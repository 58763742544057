import { useEffect } from "react";

const QRHandler = () => {
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (/iphone|ipad/.test(userAgent)) {
      window.location.href = "https://apple.co/3Z2umFN";
    } else if (/android/.test(userAgent)) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=news.eido";
    } else {
      window.location.href = "https://eido.news/";
    }
  }, []);

  return <div />;
};

export default QRHandler;
