import React from "react";
import QRCode from "../../assets/QRCode.png";
import AppStore from "../../assets/AppStore.svg";
import GooglePlayStore from "../../assets/GooglePlayStore.png";
import * as S from "./QRModal.styles";
import Modal from "react-modal";

interface QRModalProps {
  visible: boolean;
  closeModal: () => void;
}

const QRModal = ({ visible, closeModal }: QRModalProps) => {
  return (
    <Modal
      isOpen={visible}
      shouldCloseOnOverlayClick={true}
      onRequestClose={closeModal}
      style={S.styles}
    >
      <S.Container style={{ display: "flex", flexDirection: "column" }}>
        <S.Title>Pobierz aplikację Eido News</S.Title>
        <S.Subtitle>
          Zeskanuj kod QR swoim telefonem lub <br />
          przejdź do wybranego sklepu z aplikacjami
        </S.Subtitle>

        <img src={QRCode} alt="" width={250} height={250} />

        <S.ButtonsContainer>
          <img
            src={AppStore}
            height={50}
            alt="App Store"
            style={{ objectFit: "contain", cursor: "pointer" }}
            onClick={() => (window.location.href = "https://apple.co/3Z2umFN")}
          />
          <img
            src={GooglePlayStore}
            height={50}
            alt="Google Play Store"
            style={{ objectFit: "contain", cursor: "pointer" }}
            onClick={() =>
              (window.location.href =
                "https://play.google.com/store/apps/details?id=news.eido")
            }
          />
        </S.ButtonsContainer>
      </S.Container>
    </Modal>
  );
};

export default QRModal;
