import { useEffect } from "react";

const Redirect = () => {
  useEffect(() => {
    const deepLinkUrl = "news.eido://";
    window.location.href = deepLinkUrl;
  }, []);

  return <div />;
};

export default Redirect;
