import styled from "styled-components";

export const styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(249, 250, 251, 1)",
    display: "flex",
    padding: "0px",
    paddingLeft: "40px",
    paddingRight: "40px",
    borderWidth: "0px",
  },
};

export const Container = styled.h1`
  align-items: center;
  align-content: center;
  align-self: center;
  justify-content: center;
`;

export const Title = styled.p`
  font-family: Space Grotesk;
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

export const Subtitle = styled.p`
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

export const ButtonsContainer = styled.p`
  display: flex;
  flex-direction: row;
  gap: 32px;
`;
