import * as S from "./Footer.styles";

const Footer = () => {
  return (
    <S.StyledFooter>
      <S.StyledFooterContent>
        <S.Navigation style={{ paddingRight: "20px" }}>
          <S.NavLink href="/terms-and-conditions">Regulamin</S.NavLink>
          <S.NavLink href="/privacy-policy">Polityka prywatności</S.NavLink>
        </S.Navigation>
      </S.StyledFooterContent>
    </S.StyledFooter>
  );
};

export default Footer;
