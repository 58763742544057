import styled from "styled-components";

export const StyledFooter = styled.footer`
  color: #0d0d0f;
  background-color: #fcfcfc;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Title = styled.h1`
  margin: 0;
`;

export const Navigation = styled.nav`
  display: flex;
  gap: 36px;
`;

export const NavLink = styled.a`
  color: black;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledFooterContent = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between; /* Adjust navigation alignment inside */
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
`;
