import LogoLight from "../../assets/Logo";
import * as S from "./Header.styles";

interface HeaderProps {
  onModalOpen: () => void;
}

const Header = ({ onModalOpen }: HeaderProps) => {
  return (
    <S.StyledHeader>
      <div
        onClick={() => (window.location.href = "/")}
        style={{ cursor: "pointer", paddingLeft: "20px" }}
      >
        <LogoLight />
      </div>
      <S.Navigation style={{ paddingRight: "20px" }}>
        <S.NavLink href="/terms-and-conditions">Regulamin</S.NavLink>
        <S.NavLink href="/privacy-policy">Polityka prywatności</S.NavLink>
        <S.NavLink onClick={onModalOpen}>Pobierz aplikację</S.NavLink>
      </S.Navigation>
    </S.StyledHeader>
  );
};

export default Header;
