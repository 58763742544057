import styled from "styled-components";

export const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #0d0d0f;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 8px;
  border-bottom: 1px solid #eaecf0;
  width: 80%;
  background-color: #fcfcfc;
  position: fixed;
`;

export const Title = styled.h1`
  margin: 0;
`;

export const Navigation = styled.nav`
  display: flex;
`;

export const NavLink = styled.a`
  color: black;
  text-decoration: none;
  margin: 0 10px;

  &:hover {
    text-decoration: underline;
  }
`;
