import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

const Privacy = () => {
  const [data, setData] = useState<any>(undefined);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://stepbacksolutions-weurope-pure-prod-cse7cufubed0c0b3.westeurope-01.azurewebsites.net/mobile/privacy-policy/pl"
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div
      style={{
        display: "block",
        width: "80%",
        marginTop: 60,
      }}
    >
      <ReactMarkdown>{data?.content_md}</ReactMarkdown>
    </div>
  );
};

export default Privacy;
